<template>
  <div>
    <v-tabs class="mb-4" v-model="tab" color="accent" grow>
      <v-tab>
        Company Information
      </v-tab>
      <v-tab>
        Credit Score
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <CompanyInfoForm />
      </v-tab-item>
      <v-tab-item>
        <CreditScoreForm />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  components: {
    CompanyInfoForm: () => import("@/components/forms/company-information"),
    CreditScoreForm: () => import("@/components/forms/credit-score")
  },
  data: () => ({
    tab: null
  })
}
</script>
